<template>
    <div :class="{dark: dark}">
        <router-view/>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'App',

    computed: {
        ...mapState(['dark'])
    },
}
</script>