<template>
    <div class="home">
        <div class="w-full px-6 <!--sticky top-0 z-50--> bg-gray-100 dark:bg-gray-900 text-gray-500 dark:text-gray-300">
            <div class="w-full h-24 container mx-auto flex justify-between items-center">
                <div class="text-4xl font-heading font-normal">
                    <span class="text-teal-400">></span>MAKKINGA<span class="text-teal-400 blink">_</span>
                </div>
                <div @click="toggleDarkMode" class="cursor-pointer">
                    <svg v-if="dark" class="w-5 h-5" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="sun" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M256 143.7c-61.8 0-112 50.3-112 112.1s50.2 112.1 112 112.1 112-50.3 112-112.1-50.2-112.1-112-112.1zm0 192.2c-44.1 0-80-35.9-80-80.1s35.9-80.1 80-80.1 80 35.9 80 80.1-35.9 80.1-80 80.1zm256-80.1c0-5.3-2.7-10.3-7.1-13.3L422 187l19.4-97.9c1-5.2-.6-10.7-4.4-14.4-3.8-3.8-9.1-5.5-14.4-4.4l-97.8 19.4-55.5-83c-6-8.9-20.6-8.9-26.6 0l-55.5 83-97.8-19.5c-5.3-1.1-10.6.6-14.4 4.4-3.8 3.8-5.4 9.2-4.4 14.4L90 187 7.1 242.5c-4.4 3-7.1 8-7.1 13.3 0 5.3 2.7 10.3 7.1 13.3L90 324.6l-19.4 97.9c-1 5.2.6 10.7 4.4 14.4 3.8 3.8 9.1 5.5 14.4 4.4l97.8-19.4 55.5 83c3 4.5 8 7.1 13.3 7.1s10.3-2.7 13.3-7.1l55.5-83 97.8 19.4c5.4 1.2 10.7-.6 14.4-4.4 3.8-3.8 5.4-9.2 4.4-14.4L422 324.6l82.9-55.5c4.4-3 7.1-8 7.1-13.3zm-116.7 48.1c-5.4 3.6-8 10.1-6.8 16.4l16.8 84.9-84.8-16.8c-6.6-1.4-12.8 1.4-16.4 6.8l-48.1 72-48.1-71.9c-3-4.5-8-7.1-13.3-7.1-1 0-2.1.1-3.1.3l-84.8 16.8 16.8-84.9c1.2-6.3-1.4-12.8-6.8-16.4l-71.9-48.1 71.9-48.2c5.4-3.6 8-10.1 6.8-16.4l-16.8-84.9 84.8 16.8c6.5 1.3 12.8-1.4 16.4-6.8l48.1-72 48.1 72c3.6 5.4 9.9 8.1 16.4 6.8l84.8-16.8-16.8 84.9c-1.2 6.3 1.4 12.8 6.8 16.4l71.9 48.2-71.9 48z"></path>
                    </svg>
                    <svg v-if="!dark" class="w-5 h-5" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="moon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M448.964 365.617C348.188 384.809 255.14 307.765 255.14 205.419c0-58.893 31.561-112.832 82.574-141.862 25.83-14.7 19.333-53.859-10.015-59.28A258.114 258.114 0 0 0 280.947 0c-141.334 0-256 114.546-256 256 0 141.334 114.547 256 256 256 78.931 0 151.079-35.924 198.85-94.783 18.846-23.22-1.706-57.149-30.833-51.6zM280.947 480c-123.712 0-224-100.288-224-224s100.288-224 224-224c13.984 0 27.665 1.294 40.94 3.745-58.972 33.56-98.747 96.969-98.747 169.674 0 122.606 111.613 214.523 231.81 191.632C413.881 447.653 351.196 480 280.947 480z"></path>
                    </svg>
                </div>
            </div>
        </div>
    </div>

    <div class="w-screen min-h-screen bg-gray-100 dark:bg-gray-900">
        <!-- Gydo Makkinga  -->
        <div class="w-full">
            <div class="container mx-auto px-4 py-24 grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div class="flex justify-center items-center sm:order-2">
                    <img src="/img/svg/hero.svg">
                </div>

                <div class="flex justify-center items-center sm:order-1">
                    <div>
                        <h1 class="mb-6">Gydo Makkinga</h1>
                        <p>Vader van twee meiden en een gepassioneerde programmeur uit Brabant.</p>
                        <p>Als fulltime programmeur heb ik ruim {{ activeFor }} ervaring in het bouwen van maatwerk web applicaties, variërend van e-commerce websites tot complexe CRM-systemen. Ik kan helpen om jouw bedrijf online te laten groeien met een web applicatie die perfect past bij jouw wensen en behoeften.</p>
                        <p>Mijn passie ligt vooral bij het maken van maatwerk software omdat dit me de kans geeft om creatieve oplossingen te vinden die echt aansluiten bij de behoeften van mijn klanten.</p>
                        <p>Fun facts: Ik luister veel metalcore tijdens het werken, ik ben absoluut geen ochtendmens, en drink minstens 2 koppen koffie voordat ik echt goed op gang kom.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Progressive  -->
        <div class="w-full">
            <div class="container mx-auto px-4 py-24 grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div class="flex justify-center items-center">
                    <img src="/img/svg/progressive.svg">
                </div>

                <div class="flex justify-center items-center">
                    <div>
                        <h1 class="mb-6">Progressive web app</h1>
                        <p>Een PWA is een website gebouwd met moderne webtechnologieën, die de mogelijkheden van een native app benadert. Dit biedt diverse voordelen, waaronder een lagere drempel voor gebruikers om de app te installeren, doordat deze ook offline beschikbaar is. Daarnaast vinden gebruikers een PWA vaak prettiger dan een mobiele website, omdat het hele scherm van een mobiel apparaat benut wordt, net als bij een native app.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Webshop  -->
        <div class="w-full">
            <div class="container mx-auto px-4 py-24 grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div class="flex justify-center items-center sm:order-2">
                    <img src="/img/svg/webshop.svg">
                </div>

                <div class="flex justify-center items-center sm:order-1">
                    <div>
                        <h1 class="mb-6">Webshop</h1>
                        <p>Het runnen van een webshop is een bloeiende business en er wordt online steeds meer geld uitgegeven. Als je al een fysieke winkel hebt, kan het openen van een webshop een slimme zet zijn. Consumenten gebruiken tegenwoordig meerdere manieren om zich te oriënteren en uiteindelijk een aankoop te doen.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- CMS  -->
        <div class="w-full">
            <div class="container mx-auto px-4 py-24 grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div class="flex justify-center items-center">
                    <img src="/img/svg/cms.svg">
                </div>

                <div class="flex justify-center items-center">
                    <div>
                        <h1 class="mb-6 hidden sm:block">Content management</h1>
                        <h1 class="mb-6 sm:hidden">CMS</h1>
                        <p>Een content management systeem (CMS) of websitebeheersysteem is een online applicatie waarmee gebruikers gemakkelijk de inhoud van een website kunnen beheren, zonder dat specifieke technische kennis vereist is.</p>
                        <p>Voor al mijn web apps maak ik gebruik van een op maat gemaakt CMS-systeem dat volledig aan jouw wensen en eisen kan worden aangepast.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Custom solutions  -->
        <div class="w-full">
            <div class="container mx-auto px-4 py-24 grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div class="flex justify-center items-center sm:order-2">
                    <img src="/img/svg/custom.svg">
                </div>

                <div class="flex justify-center items-center sm:order-1">
                    <div>
                        <h1 class="mb-6">Maatwerk</h1>
                        <p>Veel diensten bieden tegenwoordig kant-en-klare oplossingen, zoals WordPress of SquareSpace, die werken met vooraf gebouwde thema's en plugins.</p>
                        <p>Mijn maatwerk web apps gaan echter terug naar de basis: alles wordt specifiek voor jou op maat gemaakt, vanaf het begin. Dit biedt onbeperkte mogelijkheden.</p>
                        <p>Wil je bijvoorbeeld externe systemen integreren of producten importeren? Geen enkel probleem! Ik ontwikkel oplossingen die passen bij jouw unieke behoeften en bedrijfsprocessen.</p>
                        <p>Door mijn ervaring in maatwerksoftware kan ik de perfecte oplossing bieden, zonder de beperkingen van standaardoplossingen.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Blockchain  -->
        <div class="w-full">
            <div class="container mx-auto px-4 py-24 grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div class="flex justify-center items-center">
                    <img src="/img/svg/blockchain.svg">
                </div>

                <div class="flex justify-center items-center">
                    <div>
                        <h1 class="mb-6">Crypto</h1>
                        <p>Sinds een aantal jaar ben ik ook actief in de crypto wereld, voornamelijk als applicatie ontwikkelaar voor verschillende projecten zoals oa <a class="underline decoration-2 decoration-teal-400" href="https://defikingdoms.com/" target="_blank">DeFi Kingdoms</a>, <a class="underline decoration-2 decoration-teal-400" href="https://spire.game/" target="_blank">The Spire</a> en <a class="underline decoration-2 decoration-teal-400" href="https://rebelsindisguise.co/">Rebels in Disguise</a>.</p>
                        <p>Blockchain is een nieuw soort database, waarin transacties opgeslagen kunnen worden. Dat kunnen allerlei soorten transacties zijn. In het ene geval gaat het om betalingen met een digitale munt, in het andere om belangrijke gegevens die 2 partijen uitwisselen, zoals contracten, diploma's of eigendomsbewijzen.</p>
                        <p>Zelfs deze website wordt gehost op IPFS, een gedistribueerd opslagsysteem dat gebruik maakt van blockchain-technologie!</p>
                        <p>Voor het maken van smart contracts, live charting, of transacties via bijvoorbeeld Discord, Telegram of Reddit ben je aan het juiste adres!</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contact  -->
        <div class="w-full">
            <div class="container mx-auto px-4 py-24 grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div class="flex">
                    <div>
                        <h1 class="mb-6">Contact</h1>
                        <p>Kom je hier om een afspraak te maken of heeft de bovenstaande informatie je interesse gewekt en wil je graag meer weten? Laat dan hier je gegevens achter, samen met je vraag, en ik neem zo snel mogelijk contact met je op.</p>
                    </div>
                </div>

                <ContactForm></ContactForm>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import moment from 'moment'
import ContactForm from '@/components/ContactForm'

export default {
    name: 'Home',

    components: {
        ContactForm
    },

    computed: {
        ...mapState(['dark']),

        activeFor()
        {
            moment.locale('nl')
            return moment('09-01-2012').fromNow(true)
        }
    },

    methods: {
        ...mapMutations(['toggleDarkMode'])
    }
}
</script>


