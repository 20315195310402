<template>
    <div class="grid grid-cols-1 gap-6">
        <label class="block">
            <span class="text-gray-500">Naam</span>
            <input type="text" v-model="v$.form.name.$model" autocomplete="name">
            <div v-for="(error, index) of v$.form.name.$errors" :key="index" class="text-xs text-red-500">{{ error.$message }}</div>
        </label>
        <label class="block">
            <span class="text-gray-500">E-mailadres</span>
            <input type="email" v-model="v$.form.email.$model" autocomplete="email">
            <div v-for="(error, index) of v$.form.email.$errors" :key="index" class="text-xs text-red-500">{{ error.$message }}</div>
        </label>
        <label class="block">
            <span class="text-gray-500">Telefoonnummer</span>
            <input type="tel" v-model="v$.form.phone.$model" autocomplete="phone">
            <div v-for="(error, index) of v$.form.phone.$errors" :key="index" class="text-xs text-red-500">{{ error.$message }}</div>
        </label>
        <label class="block">
            <span class="text-gray-500">Bericht</span>
            <textarea rows="5" v-model="v$.form.message.$model" autocomplete="message"></textarea>
            <div v-for="(error, index) of v$.form.message.$errors" :key="index" class="text-xs text-red-500">{{ error.$message }}</div>
        </label>

        <label class="block">
            <button @click="submit" :disabled="v$.form.$invalid" class="text-teal-400 disabled:text-gray-300 flex items-center">
                <div v-if="sending" class="mr-2 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full" role="status"></div>
                Verzenden
            </button>
        </label>
    </div>

</template>

<script>
import useVuelidate from '@vuelidate/core'
import {email, helpers, required} from '@vuelidate/validators'
import emailjs from '@emailjs/browser'

export default {
    name: 'ContactForm',

    setup()
    {
        return {v$: useVuelidate()}
    },

    data: () => ({
        sending: false,
        form   : {
            name   : '',
            email  : '',
            phone  : '',
            message: '',
        },
    }),

    validations()
    {
        return {
            form: {
                name   : {required: helpers.withMessage('Naam is verplicht', required)},
                email  : {
                    required: helpers.withMessage('E-mailadres is verplicht', required),
                    email   : helpers.withMessage('Ongeldig e-mailadres', email)
                },
                phone  : {required: helpers.withMessage('Telefoonnummer is verplicht', required)},
                message: {required: helpers.withMessage('Bericht is verplicht', required)},
            },
        }
    },

    methods: {
        async submit()
        {
            const result = await this.v$.$validate()
            if (!result) {
                return
            }

            this.sending = true

            const templateParams = {
                name   : this.form.name,
                email  : this.form.email,
                phone  : this.form.phone,
                message: this.form.message,
            }

            emailjs.send('service_tb3ov8k', 'template_vl4hkin', templateParams, 'JxonlWhauSuENwSOh')
                .then(async () => {
                    this.form.name    = ''
                    this.form.email   = ''
                    this.form.phone   = ''
                    this.form.message = ''

                    await this.$nextTick(() => {
                        this.v$.$reset()
                    })

                    this.sending = false

                    await this.$root.$toast.open({
                        message    : `Bedankt voor je bericht!`,
                        type       : 'success',
                        duration   : 5000,
                        dismissible: true
                    })
                })
        }
    }
}
</script>