import {createStore} from 'vuex'

export default createStore({
    state    : {
        dark: false
    },
    getters  : {},
    mutations: {
        toggleDarkMode(state)
        {
            state.dark = !state.dark
        }
    },
    actions  : {},
    modules  : {}
})
